@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.schedule {
  color: $colorBlack;
  background-color: $colorWhite;
  &__table {
    margin-top: 30px;
    @include mq(tab-pc) {
      margin-top: 50px;
    }
  }
  table {
    display: block;
    width: 100%;
    border-collapse: collapse;
    @include mq(tab-pc) {
      td + td:before {
        width: 1px;
        height: 84%;
        content: "";
        background: #000;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    thead {
      th {
        padding: 1em 0;
        text-align: center;
        font-size: 12px;
        border-bottom: solid 1px;
        vertical-align: middle;
      }
    }
    tbody {
      tr {
        padding: 0 0 15px;
        margin: 0 0 15px;
        @include mq(sp) {
          display: flex;
          padding: 0 0 30px;
          margin: 0 0 30px;
          border-bottom: 1px solid;
          flex-wrap: wrap;
          align-items: baseline;
        }
      }
      td {
        @include mq(tab-pc) {
          position: relative;
          padding: 25px;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 500;
          text-align: center;
          border-bottom: solid 1px;
          vertical-align: middle;
        }
      }
      .td {
        &_day {
          font-size: 18px;
          @include mq(tab-pc) {
            width: 140px;
            padding: 25px 0;
            font-size: 18px;
          }
        }
        &_time {
          @include mq(sp) {
            margin-left: 10px;
            font-size: 14px;
          }
          @include mq(tab-pc) {
            width: 120px;
            padding: 25px 0;
            font-size: 14px;
          }
        }
        &_area {
          @include mq(sp) {
            display: block;
            width: 100%;
            margin-top: 6px;
            font-size: 20px;
          }
          @include mq(tab-pc) {
            font-size: 22px;
            text-align: left;
          }
          span {
            display: inline-block;
            margin-top: 6px;
            padding: 4px 10px 5px;
            font-size: 16px;
            color: $colorRed;
            background-color: $colorBlack;
            + span {
              margin-left: 10px;
            }
          }
        }
        &_info {
          @include mq(sp) {
            margin-top: 14px;
            font-size: 14px;
          }
          @include mq(tab-pc) {
            min-width: 150px;
            text-align: left;
          }
          a {
            font-size: 12px;
            color: $colorGray;
            @include mq(tab-pc) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
