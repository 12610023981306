@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.goods {
  color: $colorWhite;
  background-color: $colorBlack;
  &__img {
    width: 100%;
    margin-top: 50px;
    @include mq() {
      margin-top: 30px;
    }
  }
}

.mt0 {
  margin-top: 0 !important;
}
