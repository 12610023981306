@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.footer {
  padding: 15px 0;
  text-align: center;
  background-color: $colorBlack;
  &-link {
    padding: 10px 16px 11px;
    color: $colorWhite;
    border-radius: 2px;
    border: 1px solid $colorWhite;
    &:hover {
      opacity: 1;
      @include mq(pc) {
        opacity: 0.7;
      }
    }
  }
  p {
    margin-top: 8px;
    font-size: 11px;
    color: $colorGray;
    &:nth-of-type(1) {
      margin-top: 40px;
    }
    @include mq(pc) {
      font-size: 12px;
    }
  }
}
