@use "./base/variables" as *;
@use "./base/mixin" as *;
@use "./base/reset";
@use "./base/base";

@use "./page/header.scss";
@use "./page/footer.scss";

@use "./page/top.scss";
@use "./page/schedule.scss";
@use "./page/ticket.scss";
@use "./page/reception.scss";
@use "./page/notes.scss";
@use "./page/goods.scss";

// plugin
@import "~remodal/dist/remodal.css";
@import "~remodal/dist/remodal-default-theme.css";
