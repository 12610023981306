@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.notes {
  color: $colorWhite;
  background-color: $colorBlack;
  h3 {
    font-size: 24px;
  }

  h4 {
    display: inline-block;
    position: relative;
    margin-top: 40px;
    padding: 4px 6px;
    font-size: 18px;
    color: $colorBlack;
    background-color: $colorWhite;
    &:before,
    &:after {
      position: absolute;
      left: 30px;
      height: 0;
      width: 0;
      border: solid transparent;
      content: "";
    }
    &:before {
      top: 100%;
      margin-left: -10px;
      border-color: transparent;
      border-top-color: $colorBlack;
      border-right-color: $colorBlack;
      border-width: 10px;
    }
    &:after {
      top: 99%;
      margin-left: -7px;
      border-color: transparent;
      border-top-color: white;
      border-right-color: white;
      border-width: 7px;
    }
  }
  &__content {
    margin-top: 40px;
    p {
      margin-top: 24px;
      line-height: 1.5;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
