@use "./_variables" as *;
@use "./_mixin" as *;

body {
  margin: 0;
  font-family: $font;
  color: $colorBlack;
  background-color: $colorBlack;
  line-height: 1.3;
  font-size: 15px;
}

h3 {
  letter-spacing: 0.15em;
  font-weight: bold;
  font-size: 28px;
  @include mq(tab-pc) {
    font-size: 46px;
  }
}

h4 {
  letter-spacing: 0.12em;
  font-weight: bold;
  font-size: 20px;
}

@include mq(pc) {
  a {
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
}

.container {
  width: 86%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
  &-small {
    width: 86%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    @include mq(pc) {
      width: 57%;
      max-width: 900px;
      margin: 0 auto;
      padding: 80px 0;
    }
  }
}

.pc {
  @include mq(sp) {
    display: none;
  }
}
.sp {
  @include mq(pc) {
    display: none;
  }
}
