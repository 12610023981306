@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.hamburger {
  position: fixed;
  width: 30px;
  height: 25px;
  right: 20px;
  top: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 10001;
  mix-blend-mode: difference;
  @include mq(pc) {
    right: 50px;
    top: 50px;
    width: 45px;
    height: 35px;
  }
  &.is-active {
    span {
      &:nth-of-type(1) {
        transform: translateY(10px) rotate(-45deg);
        @include mq(pc) {
          transform: translateY(15px) rotate(-45deg);
        }
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-10px) rotate(45deg);
        @include mq(pc) {
          transform: translateY(-15px) rotate(45deg);
        }
      }
    }
  }
  span {
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all 0.3s ease;
    mix-blend-mode: difference;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 10px;
      @include mq(pc) {
        top: 15px;
      }
    }
    &:nth-of-type(3) {
      top: 20px;
      @include mq(pc) {
        top: 30px;
      }
    }
  }
}

.remodal {
  position: relative;
  max-width: none;
  height: 100dvh;
  margin: 0;
  background-color: rgba($colorBlack, 0.8);
  &-wrapper {
    padding: 0;
  }
  ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    li {
      border-bottom: 1px solid #fff;
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 28px;
      font-weight: bold;
      color: $colorWhite;
      letter-spacing: 0.1em;
      @include mq(pc) {
        margin-bottom: 30px;
        padding-bottom: 30px;
        font-size: 46px;
      }
    }
  }
}
