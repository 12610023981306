@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.reception {
  color: $colorBlack;
  background-color: $colorWhite;
  h4 {
    position: relative;
    margin-top: 50px;
    padding: 5px 10px;
    background: $colorBlack;
    color: white;
    z-index: 0;
    &:before {
      position: absolute;
      content: "";
      right: -2px;
      top: -2px;
      border: none;
      border-right: solid 80px white;
      border-top: solid 65px transparent;
      z-index: -2;
      @include mq(pc) {
        border-top: solid 50px transparent;
      }
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
  &__title {
    color: $colorGray;
    font-size: 20px;
  }
  &__date {
    margin-top: 6px;
  }
  &__siteList {
    display: table;
    margin-bottom: 10px;
    text-decoration: underline;
    &:nth-of-type(4) {
      margin-bottom: 70px;
    }
  }
  &__inquiry {
    padding-top: 60px;
    margin-bottom: 10px;
    color: $colorGray;
    font-size: 18px;
    &-text {
      margin-top: 3px;
      a {
        color: $colorGray;
      }
    }
  }
  ul {
    margin-top: 30px;
    padding: 0 12px;
    li {
      margin-top: 24px;
    }
  }
  &__button {
    margin: 50px 0;
    text-align: center;
    @include mq(pc) {
      margin: 70px 0;
    }
    a {
      padding: 7px 13px 9px;
      color: $colorWhite;
      font-weight: bold;
      background-color: $colorGray;
      border-radius: 2px;
      font-size: 20px;
      @include mq(pc) {
        padding: 11px 15px 13px;
        font-size: 24px;
      }
      &:hover {
        opacity: 1;
        @include mq(pc) {
          opacity: 0.7;
        }
      }
    }
  }
}

.accordion {
  &__content {
    display: none;
  }
}
.accordion__title {
  font-size: 20px;
  padding: 5px 10px;
  background: $colorBlack;
  color: white;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
.accordion__title::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s;
}
.accordion__title::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.5s;
}
.accordion__title.show::before {
  opacity: 0;
}
.accordion__title.show::after {
  transform: translateY(-50%) rotate(180deg);
}
