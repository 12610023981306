@charset "utf-8";
/*================================
  color
=================================*/
$colorBlack: #000;
$colorWhite: #fff;
$colorGray: #666666;
$colorRed: red;

/*================================
  breakpoint
=================================*/
$breakpoints: (
  "sp": "screen and (max-width: 899px)",
  "tab": "screen and (min-width: 900px) and (max-width: 1279px)",
  "tab-pc": "screen and (min-width: 900px)",
  "pc": "screen and (min-width: 1280px)",
);

/*================================
  font
=================================*/
$font: "Noto Sans JP", sans-serif;
