@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.top {
  color: $colorWhite;
  background-color: $colorBlack;
  &__mv-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
  }
  &-wrap {
    padding: 40px 0 80px;
    text-align: center;
  }
  &__mv {
    padding-bottom: 30px;
    height: calc(100vh - 40px);
    &-wrap {
      display: flex;
      height: calc(100vh - 40px);
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    h1 {
      width: 58%;
      max-width: 450px;
      img {
        width: 100%;
        height: 40vh;
      }
    }
    &-character {
      height: 30vh;
    }
    h2 {
      font-weight: bold;
      letter-spacing: 0.13em;
      font-size: 28px;
      @include mq(tab-pc) {
        font-size: 36px;
      }
    }
  }

  &__comment {
    margin: 40px 0 0;
    border: 4px solid $colorWhite;
    border-radius: 20px;
    @include mq(tab-pc) {
      width: 66%;
      margin: 82px auto 0;
      font-size: 22px;
    }
    h3 {
      margin-top: 12px;
      @include mq(tab-pc) {
        margin-top: 20px;
      }
    }
    p {
      margin-top: 22px;
      line-height: 1.8;
      @include mq(tab-pc) {
        margin-top: 40px;
        font-size: 18px;
      }
    }
    img {
      width: 20%;
      margin: 12px 0;
      @include mq(tab-pc) {
        margin: 20px 0;
      }
    }
  }
}
