@use "../base/_variables" as *;
@use "../base/_mixin" as *;

.ticket {
  color: $colorWhite;
  background-color: $colorBlack;
  &__ticket {
    margin-top: 16px;
    font-size: 22px;
  }
  &__caution {
    margin-top: 30px;
    p {
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.8;
      font-size: 13px;
    }
  }
}
